var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("DatePeriodPicker", {
                        attrs: {
                          caption: "Период выборки",
                          from: _vm.from,
                          to: _vm.to,
                        },
                        on: { changed: _vm.onPerodChanged },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "2" } },
                    [
                      _c("ExchangeUserCombobox", {
                        on: { changed: _vm.userchanged },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div"),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mt-10",
            attrs: {
              dense: "",
              items: _vm.history,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "show-expand": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.opid",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.getOp(item, "id")))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.getOp(item, "status")))]
                },
              },
              {
                key: "item.action",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.getOp(item, "action")))]
                },
              },
              {
                key: "item.nickname",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.user ? item.user.nickname : "--")),
                  ]
                },
              },
              {
                key: "item.updatedAt",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getOp(item)
                            ? new Date(
                                Date.parse(_vm.getOp(item, "updated_at"))
                              ).toLocaleString()
                            : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _vm.getOp(item, "action") === "Конвертация"
                      ? _c("td", { attrs: { colspan: headers.length - 2 } }, [
                          _c("span", [
                            _vm._v(
                              " Валюта исходная: " +
                                _vm._s(_vm.money_from2(_vm.getOp(item))) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Валюта начисления: " +
                                _vm._s(_vm.money_to2(_vm.getOp(item))) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Комиссия: " +
                                _vm._s(_vm.getOp(item, "commission_amount")) +
                                " " +
                                _vm._s(
                                  _vm.getOp(item, "cryptoByCurrencyToId").title
                                ) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Размер комисии " +
                                _vm._s(_vm.getOp(item, "comission")) +
                                " % "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Баланс валюты исх " +
                                _vm._s(_vm.getOp(item, "crypto").title) +
                                " " +
                                _vm._s(_vm.getOp(item, "balance_from_before")) +
                                " -> " +
                                _vm._s(_vm.getOp(item, "balance_from_after")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Баланс валюты начисления " +
                                _vm._s(
                                  _vm.getOp(item, "cryptoByCurrencyToId").title
                                ) +
                                " " +
                                _vm._s(_vm.getOp(item, "balance_to_before")) +
                                " -> " +
                                _vm._s(_vm.getOp(item, "balance_to_after"))
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Таблица:" + _vm._s(_vm.getOp(item).c)),
                          ]),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm.getOp(item, "action") === "Наличные"
                      ? _c("td", { attrs: { colspan: headers.length - 2 } }, [
                          _c("span", [
                            _vm._v(
                              " Офис получения: " +
                                _vm._s(_vm.getOp(item, "address")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Сумма USDT: " +
                                _vm._s(_vm.getOp(item, "amount")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Город: " + _vm._s(_vm.getOp(item, "city")) + " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Комиссия: " +
                                _vm._s(_vm.getOp(item, "commission")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Валюта(фиат): " +
                                _vm._s(_vm.getOp(item, "currency")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Сумма(фиат): " +
                                _vm._s(_vm.getOp(item, "currency_amount")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Тип: " +
                                _vm._s(_vm.getOp(item, "delivery_type")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Баланс после: " +
                                _vm._s(_vm.getOp(item, "balance_now")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Баланс до: " +
                                _vm._s(_vm.getOp(item, "balance_old")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Контактные данные: " +
                                _vm._s(_vm.getOp(item, "contact")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "Забронированное время и дата: " +
                                _vm._s(_vm.getOp(item, "time")) +
                                " " +
                                _vm._s(_vm.getOp(item, "date")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Таблица:" + _vm._s(_vm.getOp(item).c)),
                          ]),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm.getOp(item, "action") === "Вывод"
                      ? _c("td", { attrs: { colspan: headers.length - 2 } }, [
                          _c("span", [
                            _vm._v(
                              " Обьем: " +
                                _vm._s(_vm.getOp(item, "amount")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Валюта: " +
                                _vm._s(_vm.getOp(item, "crypto").title) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Кошелек: " +
                                _vm._s(_vm.getOp(item, "wallet")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Таблица:" + _vm._s(_vm.getOp(item).c)),
                          ]),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm.getOp(item, "action") === "Продажа"
                      ? _c("td", { attrs: { colspan: headers.length - 2 } }, [
                          _c("span", [
                            _vm._v(
                              " Обьем: " +
                                _vm._s(_vm.getOp(item, "amount")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " ОбьемРуб: " +
                                _vm._s(_vm.getOp(item, "amountRub")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Баланс сейчас: " +
                                _vm._s(_vm.getOp(item, "balance_now")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Баланс до: " +
                                _vm._s(_vm.getOp(item, "balance_old")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Банк ID: " +
                                _vm._s(_vm.getOp(item, "bank_id")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Карта: " +
                                _vm._s(_vm.getOp(item, "cart_number")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Валюта: " +
                                _vm._s(_vm.getOp(item, "crypto").title) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Таблица:" + _vm._s(_vm.getOp(item).c)),
                          ]),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm.getOp(item, "action") === "Покупка"
                      ? _c("td", { attrs: { colspan: headers.length - 2 } }, [
                          _c("span", [
                            _vm._v(
                              " Обьем: " +
                                _vm._s(_vm.getOp(item, "amount")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " ОбьемРуб: " +
                                _vm._s(_vm.getOp(item, "amountRub")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Баланс сейчас: " +
                                _vm._s(_vm.getOp(item, "balance_now")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Баланс до: " +
                                _vm._s(_vm.getOp(item, "balance_old")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Банк ID: " +
                                _vm._s(_vm.getOp(item, "bank_id")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Карта: " +
                                _vm._s(_vm.getOp(item, "cart_number")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Валюта: " +
                                _vm._s(_vm.getOp(item, "crypto").title) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Таблица:" + _vm._s(_vm.getOp(item).c)),
                          ]),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm.getOp(item, "action") === "Депозит"
                      ? _c("td", { attrs: { colspan: headers.length - 2 } }, [
                          _c("span", [
                            _vm._v(
                              " Сумма предл.: " +
                                _vm._s(_vm.getOp(item, "amount_proposed")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Валюта: " +
                                _vm._s(
                                  _vm.getOp(item, "deposit_wallet")
                                    .crypto_network.crypto.title
                                ) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Коммент: " +
                                _vm._s(
                                  _vm.getOp(item, "deposit_wallet").description
                                ) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " ID кошелька: " +
                                _vm._s(
                                  _vm.getOp(item, "deposit_wallet").wallet
                                ) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " Чек: " + _vm._s(_vm.getOp(item, "check")) + " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `Баланс после: ${_vm
                                    .getOp(item, "balance_now")
                                    .toLocaleString("ru-RU", {
                                      style: "currency",
                                      currency: "USD",
                                    })}`
                                ) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `Баланс до: ${_vm
                                    .getOp(item, "balance_old")
                                    .toLocaleString("ru-RU", {
                                      style: "currency",
                                      currency: "USD",
                                    })}`
                                ) +
                                " "
                            ),
                          ]),
                          _c("br"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Transactions mt-10" }, [
      _c("h3", [_vm._v("История операций")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }