<template>

  <div class="ma-3">
    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions mt-10">
      <h3>История операций</h3>
    </div>


    <div class="mt-5">
      <v-form>
        <v-row>

          <v-col
              cols="6"

          >
            <DatePeriodPicker caption="Период выборки" :from="from" :to="to"
                              v-on:changed="onPerodChanged"></DatePeriodPicker>

          </v-col>

          <v-col
              cols="12"
              sm="6"
              md="2"
          >
            <ExchangeUserCombobox v-on:changed="userchanged"></ExchangeUserCombobox>


          </v-col>


        </v-row>
      </v-form>
    </div>

    <div>
    </div>


    <div>
      <v-data-table
          dense
          :items="history"
          :headers="headers"
          class="elevation-1 mt-10"
          sort-by="id"
          sort-desc
          show-expand
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':[  50,  100,  200,  -1]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.opid="{ item }"> {{ getOp(item, "id") }}</template>
        <template v-slot:item.status="{ item }"> {{ getOp(item, "status") }}</template>
        <template v-slot:item.action="{ item }"> {{ getOp(item, "action") }}</template>
        <template v-slot:item.nickname="{ item }"> {{ item.user ? item.user.nickname : "--" }}</template>

        <template v-slot:item.updatedAt="{ item }"> {{
            getOp(item) ? new Date(Date.parse(getOp(item, "updated_at"))).toLocaleString() : ""
          }}
        </template>


        <template v-slot:expanded-item="{ headers, item }">

          <td v-if="getOp(item, 'action')==='Конвертация'" :colspan="headers.length-2">

            <span> Валюта исходная:   {{ money_from2(getOp(item)) }} </span><br>
            <span> Валюта начисления: {{ money_to2(getOp(item)) }}  </span><br>
            <span> Комиссия: {{ getOp(item, 'commission_amount') }} {{
                getOp(item, 'cryptoByCurrencyToId').title
              }}  </span><br>
            <span>Размер комисии {{ getOp(item, 'comission') }} %  </span><br>
            <span>Баланс валюты исх  {{ getOp(item, 'crypto').title }} {{ getOp(item, 'balance_from_before') }} ->
              {{ getOp(item, 'balance_from_after') }}  </span><br>
            <span>Баланс валюты начисления  {{
                getOp(item, 'cryptoByCurrencyToId').title
              }} {{ getOp(item, 'balance_to_before') }} ->
              {{ getOp(item, 'balance_to_after') }}</span><br>
            <span>  Таблица:{{ getOp(item).c }}</span><br>
          </td>


          <td v-if="getOp(item, 'action')==='Наличные'" :colspan="headers.length-2">


            <span> Офис получения: {{ getOp(item, 'address') }} </span><br>
            <span>Сумма USDT:  {{ getOp(item, 'amount') }} </span><br>

            <span> Город: {{ getOp(item, 'city') }} </span><br>
            <span>Комиссия:  {{ getOp(item, 'commission') }} </span><br>
            <span> Валюта(фиат): {{ getOp(item, 'currency') }} </span><br>
            <span> Сумма(фиат): {{ getOp(item, 'currency_amount') }} </span><br>

            <span> Тип: {{ getOp(item, 'delivery_type') }} </span><br>


            <span> Баланс после: {{ getOp(item, 'balance_now') }} </span><br>
            <span> Баланс до: {{ getOp(item, 'balance_old') }} </span><br>

            <span>Контактные данные:  {{ getOp(item, 'contact') }} </span><br>
            <span>Забронированное время и дата:  {{ getOp(item, 'time') }} {{ getOp(item, 'date') }} </span><br>



            <span>  Таблица:{{ getOp(item).c }}</span><br>

          </td>


          <td v-if="getOp(item, 'action')==='Вывод'" :colspan="headers.length-2">
            <span>  Обьем: {{ getOp(item, 'amount') }} </span><br>
            <span>  Валюта: {{ getOp(item, 'crypto').title }} </span><br>

            <span>  Кошелек: {{ getOp(item, 'wallet') }} </span><br>


            <span>  Таблица:{{ getOp(item).c }}</span><br>

          </td>

          <td v-if="getOp(item, 'action')==='Продажа'" :colspan="headers.length-2">

            <span>  Обьем: {{ getOp(item, 'amount') }} </span><br>

            <span>  ОбьемРуб: {{ getOp(item, 'amountRub') }} </span><br>
            <span>  Баланс сейчас: {{ getOp(item, 'balance_now') }} </span><br>
            <span>  Баланс до: {{ getOp(item, 'balance_old') }} </span><br>
            <span>  Банк ID: {{ getOp(item, 'bank_id') }} </span><br>
            <span>  Карта: {{ getOp(item, 'cart_number') }} </span><br>
            <span>  Валюта: {{ getOp(item, 'crypto').title }} </span><br>
            <span>  Таблица:{{ getOp(item).c }}</span><br>

          </td>

          <td v-if="getOp(item, 'action')==='Покупка'" :colspan="headers.length-2">

            <span>  Обьем: {{ getOp(item, 'amount') }} </span><br>
            <span>  ОбьемРуб: {{ getOp(item, 'amountRub') }} </span><br>

            <span>  Баланс сейчас: {{ getOp(item, 'balance_now') }} </span><br>
            <span>  Баланс до: {{ getOp(item, 'balance_old') }} </span><br>
            <span>  Банк ID: {{ getOp(item, 'bank_id') }} </span><br>
            <span>  Карта: {{ getOp(item, 'cart_number') }} </span><br>
            <span>  Валюта: {{ getOp(item, 'crypto').title }} </span><br>
            <span>  Таблица:{{ getOp(item).c }}</span><br>
          </td>

          <td v-if="getOp(item, 'action')==='Депозит'" :colspan="headers.length-2">

            <span>  Сумма предл.: {{ getOp(item, 'amount_proposed') }} </span><br>
            <span>  Валюта: {{ getOp(item, 'deposit_wallet').crypto_network.crypto.title }} </span><br>
            <span>  Коммент: {{ getOp(item, 'deposit_wallet').description }} </span><br>
            <span>  ID кошелька: {{ getOp(item, 'deposit_wallet').wallet }} </span><br>
            <span>  Чек: {{ getOp(item, 'check') }} </span><br>

            <span> {{
                `Баланс после: ${getOp(item,'balance_now').toLocaleString('ru-RU', {
                  style: 'currency',
                  currency: 'USD'
                })}`
              }} </span><br>

            <span> {{
                `Баланс до: ${getOp(item,'balance_old').toLocaleString('ru-RU', {
                  style: 'currency',
                  currency: 'USD'
                })}`
              }} </span><br>


          </td>


        </template>


      </v-data-table>
    </div>

  </div>

</template>

<script>


import gql from "graphql-tag";


import DatePeriodPicker from "../components/controls/DatePeriodPicker.vue";

import Snackbar from "../components/controls/Snackbar.vue";
//import {getHeaders} from "../store";
//import {fetcher} from "../store";
//import UserCombobox from "../components/controls/UserCombobox.vue";
import ExchangeUserCombobox from "../components/controls/ExchangeUserCombobox.vue";

//const RestApiUrl = process.env.VUE_APP_REST_API_URL_MERCHANT + "/payout"

export default {
  name: 'HistoryAll',
  components: {
    ExchangeUserCombobox,


    DatePeriodPicker,
    Snackbar
  },
  apollo: {

    history: {
      query: gql`

query   q($user_id: bigint ) {
  history(order_by: {id: asc}, where: {user_id: {_eq: $user_id}}) {
    id

    cash {


    address
    amount
    balance_now
    balance_old
    city
    code
    commission
    contact
    currency
    currency_amount
    date
    delivery_type
    time


      id
      action
      created_at
      status
      updated_at
    }

    conclusions {

       amountRub
    balance_now
    balance_old
    commission
amount
wallet

     crypto {
        title
     }


      id
      action
      created_at
      status
      updated_at
    }

    conversions {

       crypto {
          title
       }
       balance_from_after
       balance_from_before
       balance_to_after
       balance_to_before
       cryptoByCurrencyToId {
        title
       }

       comission
       commission_amount

       id
       action
       created_at
       status
       updated_at
    }

    deposits {

      balance_now
      balance_old

          check
    amount_proposed
    amount_fact
    deposit_wallet {
      id
      wallet
      description
      crypto_network {
        title
        crypto {
          title
        }
      }
    }

      id
      action
      created_at
      status
      updated_at
    }
    payouts {

    amount
    amountRub
    balance_now
    balance_old
    bank_id
    cart_number
    crypto {
      id
      title
    }


      id
      action
      created_at
      status
      updated_at
    }

    transactions {

      amount
      amountRub
      balance_now
      balance_old
      bank_id
      cart_number
      crypto_id
            crypto {
        title
      }

      id
      action
      created_at
      status
      updated_at
    }
    user_id
    user {
      nickname

    }
  }
}
`,

      variables() {
        return {
          user_id: this.user_id
        }
      },

      skip() {
        return !(this.user_id > 0)
      },

      result({data}) {
        this.history = data.history;
      },

    },
  },

  data: () => ({

    user_id: 0,

    country: "",

    emptyItem: {
      bankname: null,
      amount: null,
      number: null,
      owner: null
    },

    from: "2024-01-01",
    to: "2024-12-31",

    filterByDatePeriod: false,
    payouts: [],

    headers: [
      {text: 'Ист.ID', value: 'id'},
      {text: 'Опер.ID', value: 'opid'},
      {text: 'Статус', value: 'status'},
      {text: 'Тип операции', value: 'action'},
      {text: 'Дата', value: 'updatedAt'},
      {text: 'Ник', value: 'nickname'},


    ],
  }),

  methods: {

    money_from2(item) {
      return Math.abs(item.balance_from_before - item.balance_from_after) + " " + item.crypto.title
    },

    money_to2(item) {
      return Math.abs(item.balance_to_before - item.balance_to_after) + " " + item.cryptoByCurrencyToId.title
    },

    onPerodChanged(val) {
      this.from = val.from
      this.to = val.to
    }
    ,

    getOp(histItem, member = null) {

      let i = null

      if (histItem.cash.length > 0){
        i = histItem.cash[0];
        i.c="cash"
      }
      else if (histItem.conclusions.length > 0){
        i = histItem.conclusions[0];
        i.c="conclusions"
      }
      else if (histItem.conversions.length > 0){
        i = histItem.conversions[0];
        i.c="conversions"
      }
      else if (histItem.deposits.length > 0){
        i = histItem.deposits[0];
        i.c="deposits"
      }
      else if (histItem.payouts.length > 0){
        i = histItem.payouts[0];
        i.c="payouts"
      }
      else if (histItem.transactions.length > 0){
        i = histItem.transactions[0];
        i.c="transactions"
      }

      if (!i)
        return null


      if (!member)
        return i
      else
        return i[member]


    }
    ,


    userchanged(value) {
      let id = value.id

      this.user_id = id
    }
    ,


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },
  },

}
</script>
