<template>

  <v-select
      v-model="id"
      label="Юзер Обм."
      :items="users"

      item-value="id"
      :item-text="item => item.nickname "
      return-object
      outlined
      clearable
  ></v-select>

</template>

<script>

import gql from "graphql-tag";

export default {
  name: "ExchangeUserCombobox",

  data: function () {
    return {
      dialog: false,
      users:[],
      id:null
    }
  },

  apollo: {
    $subscribe: {
      users_exchange: {
        query: gql`
subscription {
  users_exchange {
    id
    nickname
  }
}
`,
        result({data}) {

          this.users = data.users_exchange;

        },
      },
    },
  },



  watch:{
    id:function (val){
      //console.log("id=",val)

        //if (val.id){
         // console.log("val=", val )
          this.$emit('changed', val)
        //}
    },
  },




}
</script>

